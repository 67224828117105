import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <div>
  </div>
)

export default NotFoundPage
